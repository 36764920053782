export default class Storage {
    static set (name, value) {
        return window.localStorage.setItem(name, JSON.stringify(value));
    }
    static get (name) {
        return JSON.parse(window.localStorage.getItem(name));
    }
    static delete (name) {
        window.localStorage.removeItem(name);
    }
    static clear () {
        window.localStorage.clear();
    }
}