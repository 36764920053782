import React, { Component } from 'react';
import queryString from 'query-string';
import './App.css';
import '@shopify/polaris/styles.css';
import { AppProvider } from '@shopify/polaris';
import { BrowserRouter as Router, Route  } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import storage from './tools/storage';
import { client } from './tools/acuity';
import { Provider as RewormProvider } from 'reworm';
import Auth from './containers/Auth';
import PageList from './containers/PageList';

class App extends Component {
  state = {}

  componentDidMount() {
    const { accessToken } = queryString.parse(window.location.search);
    storage.set('token', accessToken);
    this.setState({ accessToken });
  }

  render() {
    const { accessToken } = this.state;

    return (
      accessToken ? (
        <RewormProvider>
          <ApolloProvider client={client}>
            <Auth>
              {({ user }) => (
                <AppProvider>
                    <Router>
                      <Route path="/" component={(props) => <PageList {...props} user={user} />} />
                    </Router>
                </AppProvider>
              )}
            </Auth>
          </ApolloProvider>
        </RewormProvider>
      ): null
    );
  }
}

export default App;
