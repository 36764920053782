import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import storage from './storage';


let connection = null;

export const getConnection = () => {
  const { shopify: { myshopify_domain, accessToken } = {} } = storage.get('user') || {};
  if (connection) return connection;

  const httpLink = createHttpLink({
    uri: `https://${myshopify_domain}/admin/api/graphql.json`
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'X-Shopify-Access-Token': accessToken
      }
    }
  });

  return connection = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });
}

export const fetchShopify = (resource, config) => {
  const { shopify: { myshopify_domain, accessToken } = {} } = storage.get('user') || {};

  return fetch(`${process.env.REACT_APP_API}/shopify/${myshopify_domain}${resource}`, {
    headers: {
      'shopify-token': accessToken
    },
    ...config
  }).then(r => r.json());
}

export const { ShopifyApp } = window;