import React, { Component } from 'react';
import { Page, Card, ResourceList, TextStyle, Link } from '@shopify/polaris';
import { ShopifyApp, fetchShopify } from '../../tools/shopify';
import storage from '../../tools/storage';

class App extends Component {
  state = {
    pages: []
  }

  async componentDidMount() {
    const { user: { shopify: { myshopify_domain } = {} } = {} } = this.props;
    const { pages = [] } = await fetchShopify('/admin/pages.json');
    const token = storage.get('token');

    if (!this.unmounted) {
      this.setState({
        pages: [{
          id: 1,
          url: `https://${myshopify_domain}?acuity=${token}`,
          title: 'Main page'
        }, ...pages.map(({ handle, ...rest }) => ({
          url: `https://${myshopify_domain}/pages/${handle}?acuity=${token}`,
          ...rest
        }))]
      });
    }
  }

  componentWillUnmount () {
    this.unmounted = true
  }

  render() {
    const { pages } = this.state;
    const token = storage.get('token');

    return (
        <Page title="Pages">
            <Card>
                <div style={{
                  padding: '1.2rem 2rem'
                }}>
                  <TextStyle variation="subdued">Click in the page to make assets shoppable</TextStyle>
                </div>
                <ResourceList
                    resourceName={{singular: 'customer', plural: 'customers'}}
                    items={pages}
                    renderItem={(page) => {
                        const { id, url, title } = page;

                        return (
                            <ResourceList.Item
                                id={id}
                                onClick={() => {
                                  ShopifyApp.remoteRedirect(url);
                                }}
                                accessibilityLabel={`View details for ${title}`}
                                >
                                <h3>
                                    <TextStyle variation="strong">{title}</TextStyle>
                                </h3>
                            </ResourceList.Item>
                        );
                    }}
                />
            </Card>
            <div class="link-botton">
              <Link onClick={() => ShopifyApp.remoteRedirect(`https://customer.acuityai.com/login?token=${token}`)}>See your assets at acuity dashboard</Link>
            </div>
        </Page>
    );
  }
}

export default App;
