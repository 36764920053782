import {
  Component
} from 'react';
import gql from 'graphql-tag';
import {
  client
} from '../../tools/acuity';
import { ShopifyApp } from '../../tools/shopify';
import storage from '../../tools/storage';

class App extends Component {
  state = {}

  componentDidMount() {
    client
      .query({
        query: gql `
          {
            user {
              id
              shopify {
                id
                email
                accessToken
                myshopify_domain
                storefrontAccessToken
              }
            }
          }
        `
      })
      .then(({
        data: { user }
      }) => {
        storage.set('user', user);
        this.setState({ user });
        
        // Initialize shopify
        ShopifyApp.init({
          apiKey: '826ede6eb120f716f4f5613d11984acc',
          shopOrigin: `https://${user.shopify.myshopify_domain}`
        });
      });
  }

  render() {
    const {
      children,
      ...rest
    } = this.props;
    const {
      user
    } = this.state;

    return children({ ...rest, user });
  }
}

export default App;